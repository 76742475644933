import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Image from "@components/image";
import styled from "@emotion/styled";

const BlogCard = ({ post, tag }) => {
    return (
      <div className="card-post">
        <Link to={post.slug}>
          <div className="featured-image card-image">
            <Image src={post.hero.full} alt={post.title} />
            {tag && (
              <div className="card-content is-overlay is-clipped">
                <span className="tag is-primary">{tag}</span>
              </div>
            )}
          </div>
          <div className="content">
            <div className="post-title">{post.title}</div>
            <span className="blog-date">
              {post.date} &middot; {post.timeToRead}
            </span>
            <p className="ar-subtitle">{post.excerpt}</p>
            <div className="post-meta">
              <div className="author-block">
                <div className="image is-32x32">
                {post.author === "Balazs Pozner" && <img src="/img/balazs_pozner.jpg" alt="balazs pozner" /> }
                {post.author === "Samuele Gugliotta" && <img src="/img/indevi0us.jpeg" alt="indevi0us" /> }
                {post.author === "Levente Molnar" && <img src="/img/levente-molnar.jpeg" alt="Levente Molnar" /> }
                </div>
                <div className="author-name">
                  <span>by  {post.author}</span>
                  <span>
                    <small>in</small> {post.tags.join()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
};
BlogCard.propTypes = {
  post: PropTypes.object.isRequired,
  tag: PropTypes.string,
};
const RoundedImage = styled(Image)`
  border-radius: 50%;
`;

export default BlogCard;
